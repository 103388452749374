import { useModal as useModalDS } from '@loveholidays/design-system';
import { useCallback, useEffect } from 'react';

import { useAppContext } from '@Contexts/contexts';

export type ModalEvent = Pick<Event, 'preventDefault' | 'stopPropagation'>;

type ModalHook<E extends ModalEvent> = [
  /**
   * modal open state
   * */
  boolean,
  /**
   *  callback to open modal
   *  */
  (event?: E) => any,
  /**
   * callback to close modal
   * */
  (event?: E) => any,
];

export const useModal = <E extends ModalEvent>(
  /**
   * initial modal state
   * */
  initialOpenState: boolean = false,
  /**
   *  Modal will close on ESC keypress by default.
   * `preventEsc` - to prevent default behavior
   * */
  preventEsc?: boolean,
): ModalHook<E> => {
  const { isWebView } = useAppContext();
  const { isOpen, setOpen, setClosed } = useModalDS(initialOpenState, preventEsc);

  const open = useCallback(
    (event?: E) => {
      if (event) {
        event.preventDefault();
      }

      if (isWebView && window.sendFrontierEvent) {
        // Frontier will call this function when it has updated the dimensions of the webview
        // this is to delay the opening of the modal until the dimensions are updated so that the modal
        // can fill the whole screen of the new dimensions
        window.frontierWebviewUpdated = () => {
          setOpen();
        };
        window.sendFrontierEvent({ name: 'openModal' });
      } else {
        setOpen();
      }
    },
    [isWebView, setOpen],
  );

  const close = useCallback(
    (event?: E) => {
      if (event) {
        event.preventDefault();
      }

      if (isWebView && window.sendFrontierEvent) {
        window.sendFrontierEvent({ name: 'closeModal' });
      }

      setClosed();
    },
    [isWebView, setClosed],
  );

  useEffect(() => {
    return () => {
      if (isOpen && isWebView && window.sendFrontierEvent) {
        window.sendFrontierEvent({ name: 'closeModal' });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isOpen, open, close];
};
